@font-face {
  font-family: 'Mona Sans';
  src:
    url('./assets/font/Mona-Sans.woff2') format('woff2 supports variations'),
    url('./assets/font/Mona-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: 'Hubot Sans';
  src:
    url('./assets/font/Hubot-Sans.woff2') format('woff2 supports variations'),
    url('./assets/font/Hubot-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

h1 {
  font-stretch: extra-expanded;
  font-family: 'Hubot Sans';

}

h2 {
  font-weight: 500;
  font-family: 'Hubot Sans';
}

p {
  font-family: 'Mona Sans';
  font-weight: 400;
  font-size: 14px;
}

span {
  font-family: 'Mona Sans';
}

html,
body {
  font-family: 'Mona Sans';
  margin: 0;
  background-color: black;
  /* Fond noir */
  color: white;
  /* Texte en blanc pour plus de contraste */

}

.main-content {
  padding: 12px;
}


body,
.button-close {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;

}


label {
  font-size: 14Px;
  color: hsl(var(--nextui-default-500));
  font-family: 'Mona Sans';
}

#root {
  height: 100%;
  background-color: black;
  /* Fond noir */
  display: flex;
  flex-direction: column;
}

:root {
  --custom-notification-color: #FF708A;
  /* Couleur personnalisée pour les notifications non lues */
}

.custom-notification-color {
  color: var(--custom-notification-color);
}

/* styles.css */
.custom-gradient-bg {
  background: linear-gradient(136deg, #2A1576 11.65%, #4F31BC 89.56%);
}

.custom-chip {
  margin: 2px;
}

.custom-chip:hover {
  background-color: hsl(var(--nextui-default-200));
  transition: 0.2s;
}


/* custom-autocomplete.css */

.pac-container {
  border-radius: 8px !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2) !important;
  background-color: #27272A !important;
  margin-top: 8px !important;
  border: none;
}


.pac-item {
  font-size: 0.875rem !important;
  padding: 8px !important;
  color: hsl(var(--nextui-default-400)) !important;
  border: 0.5px solid hsl(var(--nextui-default-700)) !important;
}

.pac-item:hover {
  background-color: #343438 !important;
  cursor: pointer;
}

.pac-item-query {
  font-size: 0.875rem !important;
  font-weight: normal !important;
  color: hsl(var(--nextui-default-200)) !important;
}

.pac-icon {
  display: none !important;
  /* Hide the pin icon */
}


@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.animated-gradient {
  background: linear-gradient(270deg, #4a2cf2, #a85cf0, #5F46F5);
  background-size: 400% 400%;
  animation: gradientAnimation 3s ease infinite;
  border: 2px solid #9179f0;
  border-radius: 8px;
  padding: 4px 12px;
  display: inline-block;
  box-shadow: 0 0 6px #5F46F5;
  /* Ombre violette */
}

.chip-content {
  color: white;
}


/* Style pour les navigateurs WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: hsl(var(--nextui-default-200));
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: hsl(var(--nextui-default-200));
}

/* Style pour Firefox */
* {
  scrollbar-width: thin;

  /* "auto" or "thin" */
  scrollbar-color: hsl(var(--nextui-default-200)) transparent;
  /* thumb and track color */
}

/* Optionnel: style pour Internet Explorer et Edge Legacy */
body {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  /* Edge Legacy */
}

/* Style pour Microsoft Edge */
body::-webkit-scrollbar {
  display: block;
  width: 2px;
  height: 2px;
}

body::-webkit-scrollbar-thumb {
  background: hsl(var(--nextui-default-200));
  border-radius: 2px;
}

body::-webkit-scrollbar-thumb:hover {
  background: hsl(var(--nextui-default-200));
}

footer {

  padding: 2rem 0;
  text-align: center;
}

footer h4 {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

footer ul {
  list-style: none;
  padding: 0;
}

footer ul li {
  margin: 0.5rem 0;
}


footer .container {
  max-width: 1200px;
  margin: 0 auto;
}

footer .grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}


.container-image-preview {
  display: flex;
  flex-direction: column;
  height: 100%;
 
}






@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.notification-icon {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  background-color: hsl(var(--nextui-danger-500));
  border-radius: 50%;
  border: #000000 1.5px solid;
}

.notification-dot {
  width: 6px;
  height: 6px;
  background-color: hsl(var(--nextui-primary-300));
  border-radius: 50%;
}

.notification-ring {
  animation: ring 6s infinite;
}

.badge-container {
  display: flex;
  gap: 10px;
}

.badge {
  width: 70px;
  height: 70px;

}

.greyscale {
  filter: grayscale(100%);
  opacity: 0.5;
}

.badge-wrapper {
  position: relative;
}

.badge-tooltip {
  z-index: 10;
}


/* Badge 3D */
.badge-3d-container {
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
}

.badge-3d {
  width: 150px;
  height: 150px;
  position: relative;

}

@keyframes shine {
  0% {
    --i: 1;
    -webkit-mask-position: 100% 100%;
  }

  50% {
    --i: -1;
    -webkit-mask-position: 0 0;
  }

  100% {
    --i: 1;
    -webkit-mask-position: 100% 100%;
  }
}

@keyframes shine-alt {
  0% {
    -webkit-mask-position: 0 100%;
  }

  50% {
    -webkit-mask-position: 100% 0;
  }

  100% {
    -webkit-mask-position: 0 100%;
  }
}



@keyframes tilt {
  0% {
    transform: rotateX(0deg) rotateY(0deg) translateY(0);
  }

  10% {
    transform: rotateX(5deg) rotateY(10deg) translateY(0);
  }

  20% {
    transform: rotateX(-10deg) rotateY(15deg) translateY(-10px);
  }

  30% {
    transform: rotateX(15deg) rotateY(-10deg) translateY(0);
  }

  40% {
    transform: rotateX(-5deg) rotateY(-15deg) translateY(-10px);
  }

  50% {
    transform: rotateX(10deg) rotateY(5deg) translateY(0);
  }

  60% {
    transform: rotateX(-15deg) rotateY(10deg) translateY(-10px);
  }

  70% {
    transform: rotateX(5deg) rotateY(-10deg) translateY(0);
  }

  80% {
    transform: rotateX(10deg) rotateY(15deg) translateY(-10px);
  }

  90% {
    transform: rotateX(-10deg) rotateY(-5deg) translateY(0);
  }

  100% {
    transform: rotateX(0deg) rotateY(0deg) translateY(0);
  }
}

.next-level {
  margin-left: 10px;
  width: 70px;
  /* adjust as needed */
  animation: none;
  opacity: 0.5;
  filter: grayscale(80%);
}

.current-badge,
.alt {

  filter: contrast(1.1);

}

.current-badge {
  -webkit-mask:
    linear-gradient(135deg, #000c 40%, #000, #000c 60%) 100% 100%/250% 250%;
  cursor: pointer;
  animation: shine 5s infinite, tilt 25s ease-in-out infinite;
  width: 150px;
  /* adjust as needed */

}


.alt {
  --r: 1, 1;
  -webkit-mask:
    linear-gradient(45deg, #000c 40%, #000, #000c 60%) 0 100%/250% 250%;
  animation: shine-alt 4s infinite;

}


.modal-size {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  overflow: auto;
  z-index: 1000;
}

.imageContainer {
  flex: 0;
  padding-top: env(safe-area-inset-top);

}

.button-fullscreen {
  display: none;
}

.div-feature {
  display: none;
}


@media (min-width: 640px) {
  footer .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .imageContainer {
    flex: 2.5;
    border: 1px solid hsl(var(--nextui-default-100));
  }

  .main-content {
    padding: 16px;
    padding-inline: 50px;
  }

  .button-fullscreen {
    display: block;
  }



}

@media (min-width: 1025px) {
  footer .grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .container-image-preview {
    flex-direction: row;
    /* En ligne à partir de 600px */


  }


  .div-feature {
    display: flex;
  }
}






.primary-gradient-text {
  background: linear-gradient(135deg, #A48CF9, #E5D4FD, #A48CF9);
  background-size: 600% 600%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: primary-gradient 10s ease infinite;
}

@keyframes primary-gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}



@keyframes loadingAnimation {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}


.fade-enter {
  opacity: 0;
  transform: scale(1.05);
  /* Commence avec un léger zoom */
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  /* Zoom retour à 1 */
  transition: opacity 1000ms ease-in-out, transform 1000ms ease-in-out;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
  /* Image à taille normale */
}

.fade-exit-active {
  opacity: 0;
  transform: scale(1.05);
  /* Zoom léger en sortant */
  transition: opacity 1000ms ease-in-out, transform 1000ms ease-in-out;
}

@keyframes slowZoom {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.5);
    /* Légère augmentation de la taille */
  }
}

.slow-zoom {
  animation: slowZoom 25s ease-in-out infinite;
  /* Durée de 20s et effet continu */
}

@keyframes Fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    /* Légère augmentation de la taille */
  }
}

.fade-content {
  animation: Fade 1s ease-in-out;
}

.modal-size.minimized {
  height: 100px;
  width: 300px;
}

.drag-handle {
  background-color: #f0f0f0;
  cursor: move;
  padding: 5px;
}


.aladin-lite-container {
  width: 100%;
  height: 600px;
}